import React, { useEffect } from 'react';

import { isBrowser } from '../../assets/js/utilities';

import './quotes.module.scss';

const Flickity = isBrowser ? require('react-flickity-component') : 'div';

const Quotes = ({ data, theme, noContainer, spacing, isSlice }) => {
  const quotes = isSlice
    ? data.primary.quotes_reference.document.data.quote
    : data.quote;

  let flkty;
  const flktyOptions = {
    wrapAround: true,
    autoPlay: 5000,
    pageDots: false,
    prevNextButtons: false,
    pauseAutoPlayOnHover: true,
    cellAlign: 'center',
    selectedAttraction: 0.03,
    friction: 0.27,
  };

  useEffect(() => {
    // Prevent body scrolling while swiping on touch devices
    if (flkty !== undefined) {
      const tapArea = flkty.element;
      let startX = 0;

      tapArea.ontouchstart = function (e) {
        startX = e.touches[0].clientX;
      };

      tapArea.ontouchmove = function (e) {
        if (Math.abs(e.touches[0].clientX - startX) > 5 && e.cancelable) {
          e.preventDefault();
        }
      };
    }
  }, [flkty]);

  const handleClick = orientation => {
    if (flkty !== undefined) {
      if (orientation === 'next') {
        flkty.next();
      } else if (orientation === 'prev') {
        flkty.previous();
      }
    }
  };

  const handlePause = () => {
    if (flkty !== undefined) {
      flkty.pausePlayer();
    }
  };

  const handleUnpause = () => {
    if (flkty !== undefined) {
      flkty.unpausePlayer();
    }
  };

  return (
    <div
      className={`${
        !noContainer ? 'o-container-fluid' : ''
      } u-mar-top-xxl ${spacing}`}
      styleName={`quotes-container ${
        theme ? 'quotes-container--' + theme : ''
      }`}
    >
      <div className="o-row" styleName="quotes">
        <div
          className="o-col o-col-lg-10 o-col-lg--offset-1"
          styleName="quotes__wrapper"
        >
          <div styleName="quotes__navigation">
            <button
              aria-label="Previous"
              onClick={() => {
                handleClick('prev');
              }}
              onMouseOver={handlePause}
              onMouseOut={handleUnpause}
              onFocus={handlePause}
              onBlur={handleUnpause}
            />
            <button
              aria-label="Next"
              onClick={() => {
                handleClick('next');
              }}
              onMouseOver={handlePause}
              onMouseOut={handleUnpause}
              onFocus={handlePause}
              onBlur={handleUnpause}
            />
          </div>

          <Flickity
            elementType={'div'}
            options={flktyOptions}
            static={true}
            reloadOnUpdate={true}
            flickityRef={e => (flkty = e)}
          >
            {quotes.map((item, i) => {
              return (
                <div styleName="quotes__quote" key={i}>
                  <blockquote
                    dangerouslySetInnerHTML={{ __html: item.quote_text.html }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.quote_author.html,
                    }}
                    className="u-align-right u-mar-top-s u-text-medium"
                  />
                </div>
              );
            })}
          </Flickity>
        </div>
      </div>
    </div>
  );
};

export default Quotes;
